@import "bootstrap/scss/bootstrap";

  // EasyAdmin
  body:not(.easyadmin-content-width-full) .wrapper {
    //width: 100%;
  }

  #list-form-filters-form {
      button {
        height: 25% !important;
        min-width: 70px;
      }
  }

  // App title
  .application-title {
    margin: auto;
  }

  // Login
  .card-container.card {
    max-width: 350px;
    padding: 40px 40px;
  }

  .card-container.card.reset-password {
    max-width: 420px;
  }
  .reset-pwd-licence {
    border: 1px solid #C6C6C6;
    border-radius: 2px;
    padding: 10px;
    margin-top: 25px;
    p {
      text-align: left;
    }
  }

  .card-login {
    form button {
      margin-top: 10px;
    }
    .reset-pwd-block {
      margin-top: 20px;
    }
  }

  .card {
    background-color: #F7F7F7;
    /* just in case there no content*/
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    /* shadows and rounded borders */
    border-radius: 2px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);

    img {
      display: block;
      margin: 0 auto 10px;
      height: 140px;
    }

    form {
      text-align: center;

      button {
        margin-top: 15px;
      }
    }
  }

  #edit-card-form {
    display: none;
  }

  .modal-footer {
    background: unset !important;
  }

  #new-card-form {
    display: none;
  }

  .details-card-book {
    background-color: #e9ecef;
    padding: 8px 20px;
    font-size: 0.8rem;
    text-align: left;

    .user-name-info {
      font-weight: bold;
    }
    p {
      margin-bottom: 0;
    }
    .update-licence {
      float: right;
    }
  }

  .custom-body  {
    padding: 10px;
  }


  // Alert
  .alert-success {
    border-color: #008d4c;
    color: #fff !important;
    background-color: #00a65a !important;
  }

  // Modify Card
  #edit-card-form {
    padding: 18px 20px 0;
    .content-footer {
      display: none;
    }
  }

  #model-form {
    text-align: right;
    padding-top: 0px;
  }

  // Show card
  #model-form.show-card {
    background: unset;

    input[type='submit'] {
      display: none;
    }
  }

  // Add new Former Card
  #former-model-form {
    padding: 0;
  }

  .new-former-form {
    text-align: right;
    .form-error {
      ul {
        list-style-type: none;
        font-size: 12px;
      }
    }
  }

  .add-former-card {
    text-align: right;
    .actions {
      text-align: right;
      padding: 10px;
    }
    .tab {
      display: none;
    }
    .actions {
      display: flex;
      justify-content: flex-end;

      #former_card_licensees_back {
        margin-right: 10px;
      }

    }
  }

  .block-homepage {
    border: 1px solid #A4A4A4;;
    padding: 20px;
    margin: 0 0 10px 5px;
  }

  .backoffice-home {
    .row {
      padding: 10px;
      .dashboards-blocks {
        width: 100%;
        .cms-block-element {
          display: flex;
        }
      }
    }
  }

  .small-box {
    border-radius: 2px;
    position: relative;
    display: block;
    margin-bottom: 20px;
    box-shadow: 0 1px 1px rgba(0,0,0,0.1);
    h3 {
      font-size: 38px;
      font-weight: bold;
      margin: 0 0 10px 0;
      white-space: nowrap;
      padding: 0;
    }
    >.inner {
      padding: 10px;
    }
    p {
      font-size: 15px;
    }
    .icon {
      -webkit-transition: all .3s linear;
      -o-transition: all .3s linear;
      transition: all .3s linear;
      position: absolute;
      top: -10px;
      right: 10px;
      z-index: 0;
      font-size: 90px;
      color: rgba(0,0,0,0.15);
    }
    >.small-box-footer {
      position: relative;
      text-align: center;
      padding: 3px 0;
      color: #fff;
      color: rgba(255,255,255,0.8);
      display: block;
      z-index: 10;
      background: rgba(0,0,0,0.1);
      text-decoration: none;
    }
  }

  .bg-aqua {
    background-color: #00c0ef !important;
    color: #fff !important;
    h3 {
      color: #fff !important;
    }
  }

  .bg-green {
    background-color: #00a65a !important;
    color: #fff !important;
    h3 {
      color: #fff !important;
    }
  }

  #list-form-filters-header {
    padding: 15px 17px 15px 20px;
  }

  .show-cardbook {
    .field-embedded_list > div.form-widget {
      margin: 0 auto;
      flex: none;
      width: 80%;
    }
  }

  body.show {
    .form-control:disabled {
      background-color: #ffffff;
    }
  }

  #licensee-model-form {
    .actions {
      text-align: right;
    }
  }

  body.a-valider #list-form-filters {
    display: none;
  }

  .jsonform-errortext {
    color: red;
    margin-right: 8px;
  }

  .show-cardmodel {
    .field-raw {
      max-width: 800px;
    }
  }

  .list-card {
    #form_filters_avalider {
      display: none;
    }
  }

  .form-control-fsgt {
    margin-top: 4px;
    margin-left: 5px;
  }

  .form-group-fsgt {
    background: var(--form-bg);
    .field-datetime {
      margin-bottom: 0px;
      padding-bottom: 26px;
    }
  }
